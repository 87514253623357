<template>
  <v-container class="pedido-realizado-container">
    <v-row class="pedido-realizado-row">
      <v-col style="text-align: center" class="pedidoRealizado-columna">
        <h1 class="pedido-realizado-titulo">¡GRACIAS!</h1>
        <p class="pedido-realizado-primer-parrafo">
          Gracias por confiar en fibra.gal. Como queremos que ya empieces a
          disfrutar de la mayor velocidad...¡ya nos hemos puesto en marcha!
        </p>
        <p>
          No queda nada para empezar a navegar a la mayor velocidad, los
          siguientes pasos son:
        </p>
        <p>
          Recibirás en tu móvil los mensajes de texto con la documentación
          preparada y lista para firmar y remitirnos. Una vez recibamos dichos
          documentos, en menos de 7 días tendrás tu fibra instalada y...¡a
          navegar!
        </p>
        <p>¡Muchas gracias de nuevo y... bienvenid@!</p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
    components: {}
}
</script>
